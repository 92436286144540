import React from 'react';
import getRanchTopRightCornerCoordinates from 'utils/getRanchTopRightCornerCoordinates';
import RanchLabel from '../RanchLabel';
import LoadingZoneIcon from '../LoadingZoneIcon';

const isMarkerInBounds = (bounds, lat, lng) => {
    if (!bounds) {
        return false;
    }

    return bounds.contains({ lat, lng });
};

export const renderRanchNameLabels = ({ name, bounds, polygons }) => {
    const topRightCornerCoordinates = getRanchTopRightCornerCoordinates(polygons);

    if (
        !topRightCornerCoordinates ||
        !isMarkerInBounds(bounds, topRightCornerCoordinates.lat, topRightCornerCoordinates.lng)
    ) {
        return;
    }

    return <RanchLabel {...topRightCornerCoordinates} key={JSON.stringify(topRightCornerCoordinates)} name={name} />;
};

const renderAdditionalMapMarkers = ({ ranches, bounds }) =>
    ranches?.reduce((acc, { loadingZoneCoordinates, name, polygons }) => {
        const ranchesNames = renderRanchNameLabels({ name, bounds, polygons });

        if (loadingZoneCoordinates) {
            const coords = { lat: loadingZoneCoordinates.lat, lng: loadingZoneCoordinates.lng };

            if (isMarkerInBounds(bounds, coords.lat, coords.lng)) {
                acc.push(<LoadingZoneIcon {...coords} key={JSON.stringify(coords)} />);
            }
        }

        return [...acc, ranchesNames];
    }, []);

export default renderAdditionalMapMarkers;
