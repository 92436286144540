const getRanchTopRightCornerCoordinates = polygons => {
    const allPolygons = polygons.flat();

    if (allPolygons.length === 0) {
        return null;
    }

    const lat = Math.max(...allPolygons.map(({ lat }) => lat));
    const lng = Math.max(...allPolygons.map(({ lng }) => lng));

    return { lat, lng };
};

export default getRanchTopRightCornerCoordinates;
