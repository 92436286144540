import React from 'react';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getBhomes, getLocations, getYards, getBeekeeperRanches } from 'components/views/Dashboard/selectors';
import renderAdditionalMapMarkers from 'components/reusables/Map/components/AdditionalMapMarkers';
import GoogleMap from 'components/reusables/Map/components/Map';
import MapControls from 'components/reusables/Map/components/Map/MapControls';
import useMap from 'components/reusables/Map/hooks/useMap';
import constants from 'appConstants';
import useCreateDrop from 'components/reusables/Map/hooks/useCreateDrop';
import useClusters from './hooks/useClusters';
import useMapCentering from './hooks/useMapCentering';
import { drawMarkers, drawYardClusters, drawLocationMarkers } from './utils';
import SearchBox from './components/SearchBox';

import './BhomeMap.scss';

const BhomeMap = () => {
    const locations = useSelector(getLocations, arrayOfObjectsShallowEqual);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const ranches = useSelector(getBeekeeperRanches, arrayOfObjectsShallowEqual);
    const yards = useSelector(getYards, arrayOfObjectsShallowEqual);

    const { mapRef, handleMapChange, mapOptions, handleMapLoad, handleOnPlacesChange } = useMap({
        ranches,
    });

    const { handleCentering } = useMapCentering({ mapRef, locations });

    const { handleSetCreateDropMode, isCreateDropMode } = useCreateDrop({ mapRef });

    const {
        bhomeClusters,
        bhomeSuperClusters,
        locationClusters,
        locationSuperClusters,
        yardClusters,
        yardSuperClusters,
    } = useClusters({ bhomes, locations, mapOptions, ranches, yards });

    const locationMarkers = drawLocationMarkers(locationClusters, locationSuperClusters, mapRef.current);
    const bhomeMarkers = drawMarkers(bhomeClusters, bhomeSuperClusters, mapRef.current);
    const yardMarkers = drawYardClusters(yardClusters, yardSuperClusters, mapRef.current);

    return (
        <section className="dashboard-map map-wrapper hybrid">
            <GoogleMap onLoad={handleMapLoad} onChange={handleMapChange}>
                {locationMarkers}
                {bhomeMarkers}
                {yardMarkers}
                {mapOptions?.zoom >= constants.SPLIT_ZOOM &&
                    renderAdditionalMapMarkers({ ranches, bounds: mapOptions.bounds })}
            </GoogleMap>
            {!!mapRef.current && (
                <>
                    <SearchBox onPlacesChanged={handleOnPlacesChange} />
                    <MapControls
                        map={mapRef.current}
                        handleAddLocation={handleSetCreateDropMode}
                        isAddingLocation={isCreateDropMode}
                        handleCentering={handleCentering}
                    />
                </>
            )}
        </section>
    );
};

export default BhomeMap;
